import { useEffect, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import Locate from "leaflet.locatecontrol";
import "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.css";

import icon from "./Marker";

export default function MapEvents() {
  const [bbox, setBbox] = useState(null);
  const [position, setPosition] = useState(null);
  const map = useMap();
  useEffect(() => {
    const locateOptions = {
      returnToPrevBounds: true,
      drawCircle: true,
      drawMarker: true,
      showPopUp: true,
      enableHighAccuracy: true,
      maxZoom: 19,
      flyTo: true,
      strings: {
        title: "Show me where I am!",
      },
    };

    const lc = new Locate(locateOptions);
    lc.addTo(map);
  }, [map]);

  return position === null ? null : (
    <Marker position={position} icon={icon}>
      <Popup>
        You are here. <br />
        Map bbox: <br />
        <b>Southwest lng</b>: {bbox[0]} <br />
        <b>Southwest lat</b>: {bbox[1]} <br />
        <b>Northeast lng</b>: {bbox[2]} <br />
        <b>Northeast lat</b>: {bbox[3]}
      </Popup>
    </Marker>
  );
}