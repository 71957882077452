import axios from "axios";
import { GeoJSON, useMap } from "react-leaflet";
import { useAsync } from "react-async";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { useState } from "react";

const Popup = ({ feature }) => {
  return (
    <div>
      <p>
        <b>Plot Number: </b> <span>{feature.properties.plot_nmb}</span>
      </p>
      <p>
        <b>Plot Size: </b> <span>{feature.properties.plot_size}</span>
      </p>
      <p>
        <b>Block: </b> <span>{feature.properties.block}</span>
      </p>
      <p>
        <b>Road: </b> <span>{feature.properties.road_name}</span>
      </p>
      <p>
        <b>Society: </b> <span>{feature.properties.soc_name}</span>
      </p>
    </div>
  );
};

const getData = async ({ socName }) => {
  const res = await axios.get(
    // `../geoserver/plotsmap/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=plotsmap:csc_east_plots&outputFormat=application/json`
    `../geoserver/plotsmap/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=plotsmap:${socName}&outputFormat=application/json`
  );
  if (!res.data) throw new Error(res.statusText);
  return res.data;
};

const VectorData = (props) => {
  const map = useMap();

  // console.log(map.getBounds());
  // map.on("moveend", load_wfs);
  // load_wfs(map);
  // const [data, setData] = useState([]);
  const { data, error, isPending } = useAsync({
    promiseFn: getData,
    socName: "csc_plots",
  });
  if (isPending) return "Loading...";
  if (error) return `Something went wrong: ${error.message}`;

  // function load_wfs() {
  //   if (map.getZoom() > 13) {
  //     var geoJsonUrl = "../geoserver/wfs";
  //     var defaultParameters = {
  //       service: "WFS",
  //       version: "1.0.0",
  //       request: "getFeature",
  //       typeName: "plotsmap:csc_plots",
  //       // maxFeatures: 3000,
  //       outputFormat: "application/json",
  //       // format_options: "callback: getJson",
  //       // srsName: "EPSG:4326",
  //     };

  //     var customParams = {
  //       bbox: map.getBounds().toBBoxString(),
  //     };
  //     var parameters = L.Util.extend(defaultParameters, customParams);
  //     // console.log(geoJsonUrl + L.Util.getParamString(parameters));
  //     const res = axios
  //       .get(geoJsonUrl + L.Util.getParamString(parameters))
  //       .then((data) => (setData(data.data)));
  //     // console.log(res);
  //     // $.ajax({
  //     //   jsonp: false,
  //     //   url: geoJsonUrl + L.Util.getParamString(parameters),
  //     //   dataType: "jsonp",
  //     //   jsonpCallback: "getJson",
  //     //   success: loadGeoJson,
  //     // });
  //   } else {
  //     // console.log("wait");
  //     // featureLayer.clearLayers();
  //   }
  // }

  function highlightFeature(e) {
    // var layer = e.target;
    // props.featureHighlighted(e.target.feature);
    // layer.setStyle({
    //   // fillColor: selectedDD ? getColor(e.target.feature.properties.id) : "#b2b2b2",
    //   weight: 2,
    //   // color: "#333",
    //   dashArray: "3",
    //   fillOpacity: 0.7,
    // });
    // layer.bringToFront();
  }

  function resetHighlight(e) {
    // props.featureUnHighlighted();
    // var layer = e.target;
    // layer.setStyle({
    //   // fillColor: selectedDD ? getColor(e.target.feature.properties.id) : "#b2b2b2",
    //   weight: 2,
    //   // color: "white",
    //   dashArray: "3",
    //   fillOpacity: 0.7,
    // });
  }

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
    });
    const popupContent = ReactDOMServer.renderToString(
      <Popup feature={feature} />
    );
    layer.bindPopup(popupContent);
  };
  function getStyle(feature) {
    return {
      // fillColor: selectedDD ? getColor(feature.properties.id) : "#b2b2b2",
      fillColor: "red",
      weight: 2,
      color: "white",
      dashArray: "3",
      fillOpacity: 0.5,
    };
  }

  if (data) {
    return (
      <GeoJSON
        data={data.features}
        style={getStyle}
        onEachFeature={onEachFeature}
      />
    );
  } else {
    return "";
  }
};

export default VectorData;
